@import '../_variables.scss';

.three-col-grid {
	.container-wrap {
		padding: 0 1em;
        @media (min-width: $lg) {
			padding: 0 2em;
		}
	}

	.filter {
		text-align: center;
	}

	@media (min-width: $md) {
		.grid {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.col-item {
		padding-top: 1em;
		padding-bottom: 1em;
		padding-left: 1em;
		padding-right: 1em;
		@media (min-width: $md) {
			flex: 0 0 33.33%;
		}
	}

	.grid-item {
		position: relative;
		display: block;
		padding-bottom: 77.5%;
		overflow: hidden;
	}

	.grid-image {
		background-color: #1e242c;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		border-radius: $grid-border-radius;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			content: "";
			border-radius: $grid-border-radius;
			//box-shadow: inset 0 0 10em rgba(0,0,0, 0.7);
		}
		

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: $grid-border-radius;
		}
	}
	.grid-info {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
		text-align: center;

		img {
			width: 100%;
		}
		&.text h3 {
			color: #fff;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 2em;
            @media (min-width: $xl) {
				font-size: 3em;
			}
		}
	}
}
