@import '../_variables.scss';

footer {
	background: $footer-bg;
	color: $footer-color;
	padding: 1em;
	width: 100%;
	text-align: center;
	.links {
		font-size: 1.5em;
		list-style-type: none;
		a {
			color: $primary-color;
		}
		li {
			display: inline;
			margin: 0 1em 0 0;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.copyright {
		color: $dark-grey;
	}
}