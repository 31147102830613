@import '../_variables.scss';

.hero-container {
	background-image: $header-bg-img;
	background-repeat: no-repeat;
	background-position: 50%;
	background-attachment: fixed;
	background-size: cover;
	width: 100%;
	height: 100vh;
	color: $primary-color-light;
	display: flex;
	align-items: center;
	position: relative;
	&.short {
		height: 25em;
		text-transform: uppercase;
		position: 'relative';
		background-image: 'none';
		.introduction img {
			width: 75%;
            @media (min-width: $lg) {
				width: auto;
			}
		}
	}
	.introduction {
		line-height: 1.2em;
		margin: 0 auto;
		text-align: center;
		h1 {
			font-size: 3.75em;
			font-weight: bold;
			span {
				color: $feature-color;
			}
		}
		p {
			font-size: 1.72em;
			font-weight: bold;
		}

		@media (min-width: $md) {
			width: 50%;
			h1 {
				font-size: 4.65em;
			}
			p {
				font-size: 2.2em;
			}
		}

		@media (min-width: $lg) {
			width: 50%;
			h1 {
				font-size: 5em;
			}
			p {
				font-size: 2.3em;
			}
		}

	}
	.header-bottom {
		bottom: -10px;
		left: -7px;
	}
}