@import '_variables';
@import '_reset';
@import 'components/atoms/_fonts';

body {
	background: $body;
	font-family: $primary-font;
	color: $primary-color;
	overflow-x: hidden;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
}

a {
	&:hover {
		text-decoration: none;
	}
}


h1,h2,h3,h4,h5,h6 {
	margin-bottom: .5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

// .wpjs {
// 	opacity: 0;
// }

.svg-border {
	position: absolute;
	margin: 0;
	padding: 0;
	width: calc(100% + 10px);
	display: block;
}


.section-container {
	clear: left;
	padding: 2.5em 0;
	h2 {
		font-size: 2.25em;
		font-weight: bold;
		text-align: center;
		margin: 0 0 1.2em 0;
		&.featured {
			color: $feature-color;
		}
	}
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin: 0 auto;
	@media (min-width: $lg) {
		width: 75%;
		max-width: 960px;
	}
}


.contact {
	position: relative;
	background: $contact-bg;
	padding: 6em 0 2.5em 0;
	.contact-top {
		top: -6px;
		left: -5px;
	}
	.form {
		@media (min-width: $lg) {
			width: 75%;
		}
	}
}