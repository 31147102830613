@import '../_variables.scss';

.img-txt-container {
	font-weight: 300;

	.image {
		text-align: center;
		@media (min-width: $md) {
			text-align: left;
		}
	}

	.row {
		@media (min-width: $md) {
			display: flex;
		}
	}

	.image {
		text-align: center;
		@media (min-width: $md) {
			text-align: center;
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}
	}
	.introduction {
		margin-top: 1.7rem;
		p {
			line-height: 1.7em;
		}

		@media (min-width: $md) {
			flex: 0 0 66.666%;
			max-width: 66.666%;
		}
	}
}