@import '../_variables.scss';

.one-column-txt-container {
	font-weight: 300;
	
	p { line-height: 1.7em; }
	strong { font-weight: bold; }

	.skills {
		padding-top: 3em;
		.tags-container {
			display: inline-flex;
			flex-wrap: wrap;
		}
		.tag {
			color: #fff;
			font-weight: bold;
			text-transform: uppercase;
			background: $feature-color;
			padding: 0.5em 1em;
			margin: 0.2em;
		}
	}
}