@import '../_variables.scss';

.form {
	.error-container {
		padding: 10px 5px;
		color: red;
	}
	.success-container {
		padding: 10px 5px;
		color: green;
	}
}

form {
	.row {
		margin: 5px 0;
	}
    span.error {
        display: inline-block;
        color: red;
        margin: 10px 0 ;
    }
	input,textarea {
		border: 1px solid $form-input-bg;
	}
	input[type="text"], input[type="email"], textarea {
		color: $form-color;
		background: $form-input-bg;
		padding: 1em;
		width: 100%;
		border-radius: .5em;
        &.error {
            border: 1px solid red !important;
        }
	}
	input[type="submit"], input[type="button"] {
		margin: 1.5em 0;
	}
}